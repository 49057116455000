/**
 * Note: these classes must be explicitly defined for Tailwind to pick them up
 *
 * In other words, we can't do something like ${variable}-white, or else Tailwind won't compile the class
 */

export const getCSSColorBg = () => {
  return {
    white: `bg-white`,
    primary: `bg-primary`,
    secondary: `bg-secondary`,
    blue: `bg-blue`,
    grey: `bg-grey`,
    lightGrey: `bg-lightGrey`,
    darkGrey: `bg-darkGrey`,
    black: `bg-black`,
  };
};

export const getCSSColorHoverBg = () => {
  return {
    white: `hover:bg-white`,
    primary: `hover:bg-primary`,
    secondary: `hover:bg-secondary`,
    blue: `hover:bg-blue`,
    grey: `hover:bg-grey`,
    lightGrey: `hover:bg-lightGrey`,
    darkGrey: `hover:bg-darkGrey`,
    black: `hover:bg-black`,
  };
};

export const getCSSColorText = () => {
  return {
    white: `text-white`,
    primary: `text-primary`,
    secondary: `text-secondary`,
    blue: `text-blue`,
    grey: `text-grey`,
    lightGrey: `text-lightGrey`,
    darkGrey: `text-darkGrey`,
    black: `text-black`,
  };
};

export const getCSSColorHoverText = () => {
  return {
    white: `hover:text-white`,
    primary: `hover:text-primary`,
    secondary: `hover:text-secondary`,
    blue: `hover:text-blue`,
    grey: `hover:text-grey`,
    lightGrey: `hover:text-lightGrey`,
    darkGrey: `hover:text-darkGrey`,
    black: `hover:text-black`,
  };
};

export const getCSSColorChildLinks = () => {
  return {
    white: `[&_a]:text-white`,
    primary: `[&_a]:text-primary`,
    secondary: `[&_a]:text-secondary`,
    blue: `[&_a]:text-blue`,
    grey: `[&_a]:text-grey`,
    lightGrey: `[&_a]:text-lightGrey`,
    darkGrey: `[&_a]:text-darkGrey`,
    black: `[&_a]:text-black`,
  };
};

export const getCSSColorBorder = () => {
  return {
    white: `border-white`,
    primary: `border-primary`,
    secondary: `border-secondary`,
    blue: `border-blue`,
    grey: `border-grey`,
    lightGrey: `border-lightGrey`,
    darkGrey: `border-darkGrey`,
    black: `border-black`,
  };
};

export const getCSSColorHoverBorder = () => {
  return {
    white: `hover:border-white`,
    primary: `hover:border-primary`,
    secondary: `hover:border-secondary`,
    blue: `hover:border-blue`,
    grey: `hover:border-grey`,
    lightGrey: `hover:border-lightGrey`,
    darkGrey: `hover:border-darkGrey`,
    black: `hover:border-black`,
  };
};

export const getCSSButtonBorderWidth = () => {
  return {
    '0': `border-0`,
    '1': `border`,
    '2': `border-2`,
    '4': `border-4`,
  };
};

export const getCSSBorderTopWidth = () => {
  return {
    0: 'border-t-0',
    0.1: 'border-t-2',
    0.25: 'border-t-[5px]',
  };
};

export const getCSSBorderBottomWidth = () => {
  return {
    0: 'border-b-0',
    0.1: 'border-b-2',
    0.25: 'border-b-[5px]',
  };
};

export const getCSSBorderBottomHoverWidth = () => {
  return {
    0: 'hover:border-b-0',
    0.1: 'hover:border-b-2',
    0.25: 'hover:border-b-[5px]',
  };
};

export const getCSSPaddingTop = () => {
  return {
    0: 'pt-0',
    0.1: 'pt-0.5',
    0.25: 'pt-[5px]',
  };
};

export const getCSSPaddingBottom = () => {
  return {
    0: 'pb-0',
    15: 'pb-4',
    20: 'pb-5',
    30: 'pb-7.5',
    40: 'pb-10',
  };
};

export const getCSSPaddingVertical = () => {
  return {
    0: 'py-0',
    15: 'py-4',
    20: 'py-5',
    30: 'py-7',
    40: 'py-10',
  };
};

export const getCSSPaddingTopHover = () => {
  return {
    0: 'hover:pt-0',
    0.1: 'hover:pt-0.5',
    0.25: 'hover:pt-[5px]',
  };
};

export const getCSSBorderRadius = () => {
  return {
    '20': `rounded-[20px]`,
  };
};

export const getCSSColorGroupHoverText = () => {
  return {
    white: `group-hover:text-white`,
    primary: `group-hover:text-primary`,
    secondary: `group-hover:text-secondary`,
    blue: `group-hover:text-blue`,
    grey: `group-hover:text-grey`,
    lightGrey: `group-hover:text-lightGrey`,
    darkGrey: `group-hover:text-darkGrey`,
    black: `group-hover:text-black`,
  };
};

export const getCSSColorGroupNavHoverText = () => {
  return {
    white: `group-hover/topnav:text-white`,
    primary: `group-hover/topnav:text-primary`,
    secondary: `group-hover/topnav:text-secondary`,
    blue: `group-hover/topnav:text-blue`,
    grey: `group-hover/topnav:text-grey`,
    lightGrey: `group-hover/topnav:text-lightGrey`,
    darkGrey: `group-hover/topnav:text-darkGrey`,
    black: `group-hover/topnav:text-black`,
  };
};

export const getCSSColorGroupDropdownHoverText = () => {
  return {
    white: `group-hover/dropdown:text-white`,
    primary: `group-hover/dropdown:text-primary`,
    secondary: `group-hover/dropdown:text-secondary`,
    blue: `group-hover/dropdown:text-blue`,
    grey: `group-hover/dropdown:text-grey`,
    lightGrey: `group-hover/dropdown:text-lightGrey`,
    darkGrey: `group-hover/dropdown:text-darkGrey`,
    black: `group-hover/dropdown:text-black`,
  };
};

export const getCSSGridTemplateRows = (): Record<string, string> => {
  return {
    '1': 'grid-rows-1',
    '2': 'grid-rows-2',
    '3': 'grid-rows-3',
    '4': 'grid-rows-4',
    '5': 'grid-rows-5',
    '6': 'grid-rows-6',
    '7': 'grid-rows-7',
  };
};

export const getCSSGridTemplateColumns = (): Record<number, string> => {
  return {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    7: 'grid-cols-7',
    8: 'grid-cols-8',
    9: 'grid-cols-9',
    10: 'grid-cols-10',
  };
};

export const getCSSColorFill = () => {
  return {
    white: `fill-white`,
    primary: `fill-primary`,
    secondary: `fill-secondary`,
    blue: `fill-blue`,
    grey: `fill-grey`,
    lightGrey: `fill-lightGrey`,
    darkGrey: `fill-darkGrey`,
    black: `fill-black`,
  };
};

export const getCSSColorPolygonFill = () => {
  return {
    white: `[&_polygon]:fill-white`,
    primary: `[&_polygon]:fill-primary`,
    secondary: `[&_polygon]:fill-secondary`,
    blue: `[&_polygon]:fill-blue`,
    grey: `[&_polygon]:fill-grey`,
    lightGrey: `[&_polygon]:fill-lightGrey`,
    darkGrey: `[&_polygon]:fill-darkGrey`,
    black: `[&_polygon]:fill-black`,
  };
};

export const getCSSColorSVGStroke = () => {
  return {
    white: `[&>svg]:stroke-white`,
    primary: `[&>svg]:stroke-primary`,
    secondary: `[&>svg]:stroke-secondary`,
    blue: `[&>svg]:stroke-blue`,
    grey: `[&>svg]:stroke-grey`,
    lightGrey: `[&>svg]:stroke-lightGrey`,
    darkGrey: `[&>svg]:stroke-darkGrey`,
    black: `[&>svg]:stroke-black`,
  };
};

export const getCSSColorSVGFill = () => {
  return {
    white: `[&>svg]:fill-white`,
    primary: `[&>svg]:fill-primary`,
    secondary: `[&>svg]:fill-secondary`,
    blue: `[&>svg]:fill-blue`,
    grey: `[&>svg]:fill-grey`,
    lightGrey: `[&>svg]:fill-lightGrey`,
    darkGrey: `[&>svg]:fill-darkGrey`,
    black: `[&>svg]:fill-black`,
  };
};

export const getCSSColorSVGHoverFill = () => {
  return {
    white: `[&>svg]:hover:fill-white`,
    primary: `[&>svg]:hover:fill-primary`,
    secondary: `[&>svg]:hover:fill-secondary`,
    blue: `[&>svg]:hover:fill-blue`,
    grey: `[&>svg]:hover:fill-grey`,
    lightGrey: `[&>svg]:hover:fill-lightGrey`,
    darkGrey: `[&>svg]:hover:fill-darkGrey`,
    black: `[&>svg]:hover:fill-black`,
  };
};

export const getCSSSizeSmText = () => {
  return {
    big: 'font-group-big',
    bigger: 'font-group-bigger',
    gigantic: 'font-group-gigantic',
    huge: 'font-group-huge',
    large: 'font-group-large',
    normal: 'font-group-normal',
    tiny: 'font-group-tiny',
  };
};

export const getCSSSizeMdText = () => {
  return {
    big: 'md:font-group-big',
    bigger: 'md:font-group-bigger',
    gigantic: 'md:font-group-gigantic',
    huge: 'md:font-group-huge',
    large: 'md:font-group-large',
    normal: 'md:font-group-normal',
    tiny: 'md:font-group-tiny',
  };
};

export const getCSSSizeLgText = () => {
  return {
    big: 'lg:font-group-big',
    bigger: 'lg:font-group-bigger',
    gigantic: 'lg:font-group-gigantic',
    huge: 'lg:font-group-huge',
    large: 'lg:font-group-large',
    normal: 'lg:font-group-normal',
    tiny: 'lg:font-group-tiny',
  };
};

export const getCSSTextAlign = () => {
  return {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
    justify: 'text-justify',
  };
};

export const getCSSVerticalSpacingFactor = () => {
  return {
    0: 'my-0',
    0.5: 'my-f-0.5',
    1: 'my-f-1',
    1.3: 'my-f-1.3',
    1.5: 'my-f-1.5',
    2: 'my-f-2',
  };
};

export const getCSSHeightSmFactor = () => {
  return {
    0: 'h-0',
    0.1: 'h-f-0.1',
    0.25: 'h-f-0.25',
    0.5: 'h-f-0.5',
    0.75: 'h-f-0.75',
    1: 'h-f-1',
    1.25: 'h-f-1.25',
    1.5: 'h-f-1.5',
    1.75: 'h-f-1.75',
    2: 'h-f-2',
    2.25: 'h-f-2.25',
    2.5: 'h-f-2.5',
    2.75: 'h-f-2.75',
    3: 'h-f-3',
    3.25: 'h-f-3.25',
    3.5: 'h-f-3.5',
    3.75: 'h-f-3.75',
    4: 'h-f-4',
    4.25: 'h-f-4.25',
    4.5: 'h-f-4.5',
    4.75: 'h-f-4.75',
    5: 'h-f-5',
  };
};
export const getCSSHeightMdFactor = () => {
  return {
    0: 'md:h-0',
    0.1: 'md:h-f-0.1',
    0.25: 'md:h-f-0.25',
    0.5: 'md:h-f-0.5',
    0.75: 'md:h-f-0.75',
    1: 'md:h-f-1',
    1.25: 'md:h-f-1.25',
    1.5: 'md:h-f-1.5',
    1.75: 'md:h-f-1.75',
    2: 'md:h-f-2',
    2.25: 'md:h-f-2.25',
    2.5: 'md:h-f-2.5',
    2.75: 'md:h-f-2.75',
    3: 'md:h-f-3',
    3.25: 'md:h-f-3.25',
    3.5: 'md:h-f-3.5',
    3.75: 'md:h-f-3.75',
    4: 'md:h-f-4',
    4.25: 'md:h-f-4.25',
    4.5: 'md:h-f-4.5',
    4.75: 'md:h-f-4.75',
    5: 'md:h-f-5',
  };
};
export const getCSSHeightLgFactor = () => {
  return {
    0: 'lg:h-0',
    0.1: 'lg:h-f-0.1',
    0.25: 'lg:h-f-0.25',
    0.5: 'lg:h-f-0.5',
    0.75: 'lg:h-f-0.75',
    1: 'lg:h-f-1',
    1.25: 'lg:h-f-1.25',
    1.5: 'lg:h-f-1.5',
    1.75: 'lg:h-f-1.75',
    2: 'lg:h-f-2',
    2.25: 'lg:h-f-2.25',
    2.5: 'lg:h-f-2.5',
    2.75: 'lg:h-f-2.75',
    3: 'lg:h-f-3',
    3.25: 'lg:h-f-3.25',
    3.5: 'lg:h-f-3.5',
    3.75: 'lg:h-f-3.75',
    4: 'lg:h-f-4',
    4.25: 'lg:h-f-4.25',
    4.5: 'lg:h-f-4.5',
    4.75: 'lg:h-f-4.75',
    5: 'lg:h-f-5',
  };
};

export const getCSSSectionHeader = () => {
  return {
    borderTopWidth: {
      ...getCSSBorderTopWidth(),
    },
    borderTopColor: {
      ...getCSSColorBorder(),
    },
  };
};

export const getCSSTopSmFactor = () => {
  return {
    1: 'top-f-1',
    1.5: 'top-f-1.5',
    2: 'top-f-2',
    2.5: 'top-f-2.5',
    3: 'top-f-3',
    4: 'top-f-4',
  };
};
export const getCSSTopMdFactor = () => {
  return {
    1: 'md:top-f-1',
    1.5: 'md:top-f-1.5',
    2: 'md:top-f-2',
    2.5: 'md:top-f-2.5',
    3: 'md:top-f-3',
    4: 'md:top-f-4',
  };
};
export const getCSSTopLgFactor = () => {
  return {
    1: 'lg:top-f-1',
    1.5: 'lg:top-f-1.5',
    2: 'lg:top-f-2',
    2.5: 'lg:top-f-2.5',
    3: 'lg:top-f-3',
    4: 'lg:top-f-4',
  };
};

export const getCSSColumnGapFactor = () => {
  return {
    0.75: 'gap-x-f-0.75',
    1: 'gap-x-f-1',
  };
};
